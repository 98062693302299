import "@wix/design-system/styles.global.css";
import {
  Text,
  Box,
  Heading,
  Page,
  WixDesignSystemProvider,
} from "@wix/design-system";
import * as Icons from "@wix/wix-ui-icons-common";

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const appId = urlParams.get("appId");
  const state = urlParams.get("state");
  const lang = urlParams.get("lang");
  // ecpay shipments: success
  if (appId === "7363d59c-3549-41bf-b6fe-96e0f9c0676b") {
    if (state === "success")
      return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
          <Page height="100vh" minWidth={0}>
            <Page.Content>
              <Box height={"90vh"} direction="vertical" verticalAlign="middle">
                <Box align="center" direction="vertical">
                  <Icons.StatusComplete size="54" />
                  <Heading appearance="H3">成功</Heading>
                  <Text size="medium">您已完成所需步驟，請關閉此頁面。</Text>
                </Box>
              </Box>
            </Page.Content>
          </Page>
        </WixDesignSystemProvider>
      );
    if (state === "error")
      return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
          <Page height="100vh" minWidth={0}>
            <Page.Content>
              <Box height={"90vh"} direction="vertical" verticalAlign="middle">
                <Box align="center" direction="vertical">
                  <Icons.StatusComplete size="54" />
                  <Heading appearance="H3">發生一個錯誤</Heading>
                  <Text size="medium">請聯繫商店完成出貨。</Text>
                </Box>
              </Box>
            </Page.Content>
          </Page>
        </WixDesignSystemProvider>
      );
  }
  if (appId === "site" && state === "disabled") {
    if (lang === "zh") {
      return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
          <Page height="100vh" minWidth={0}>
            <Page.Content>
              <Box height={"90vh"} direction="vertical" verticalAlign="middle">
                <Box align="center" direction="vertical">
                  <Icons.SiteSearch size="54" />
                  <Heading appearance="H3">此網站尋遍不獲！</Heading>
                  {/* @ts-ignore */}
                  <Text size="medium">
                    如果您是網站擁有人，請聯絡{" "}
                    <code>team@certifiedcode.us</code> 以獲得幫助。
                  </Text>
                </Box>
              </Box>
            </Page.Content>
          </Page>
        </WixDesignSystemProvider>
      );
    }
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh" minWidth={0}>
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Box align="center" direction="vertical">
                <Icons.SiteSearch size="54" />
                <Heading appearance="H3">
                  Site not available at this moment
                </Heading>
                {/* @ts-ignore */}
                <Text size="medium">
                  If you are the site owner, please contact{" "}
                  <code>team@certifiedcode.us</code> for further assist.
                </Text>
              </Box>
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh" minWidth={0}>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Box align="center" direction="vertical">
              <Icons.Check size="54" />
              <Heading appearance="H3">No action required</Heading>
              <Text size="medium">You can close this window.</Text>
            </Box>
          </Box>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
